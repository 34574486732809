<script>

</script>

<template>
  <div class="page container">
    <h6>CONTACT</h6>
    <div class="contact">
      <h3>Russia, Moscow,</h3>
      <h3>Pravdy Street 23/4</h3>
      <h3><a href="tel:+79104299909">+79104299909</a></h3>
      <h3><a href="mailto:yan@oneonone.team">yan@oneonone.team</a></h3>
    </div>
    <div class="contact">
      <h3>UAE, Dubai, </h3>
      <h3>Palm Jumeirah, Sunrise Bay Tower 2</h3>
      <h3><a href="tel:+971569504947">+971569504947</a></h3>
      <h3><a href="mailto:alex@oneonone.team">alex@oneonone.team</a></h3>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page {
  padding-top: 200px;
  text-align: center;

  a {
    text-decoration: underline;
  }

  h6 {
    margin-bottom: 18px;
  }

  .contact {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 809px) {
  .page {
    padding-top: 170px;
  }
}
</style>