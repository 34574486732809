<script>
import HomeWork from '@/components/HomePage/HomeWork.vue';
import ContactForm from '@/components/Forms/ContactForm.vue';
import LogoScroller from "@/components/LogoScroller.vue";
import {mapGetters, mapActions} from 'vuex';

export default {
  components: {
    HomeWork,
    ContactForm,
    LogoScroller
  },
  computed: {
    ...mapGetters(['getPosts']),
    posts() {
      return this.getPosts?.posts;
    },
  },

  created() {
    this.fetchPosts().then(() => {
      this.posts = this.posts.reverse(); // Используйте 'data' вместо 'fetched'
      for (let i = 0; (i < 6) && (i < this.posts.length); i++) {
        this.articles.push(this.posts[i])
      }
      console.log(this.articles)
    });
  },

  data() {
    return {
      articles: [],
      gridArticles: [],
      mainArticle: {}
    }
  },
  beforeMount() {

  },
  methods: {
    ...mapActions(['fetchPosts']),
    loadMore(e) {
      let i = this.gridArticles.length + 1;
      let j;


      if (this.articles.length - i < 10) {
        j = this.gridArticles.length + this.articles.length - i;
        this.$refs.moreBtn.classList.add('hidden');
      } else {
        j = i + 9;
      }

      for (i; i <= j; i++) {
        this.gridArticles.push(this.articles[i]);
      }
    },
  }

}
</script>

<template>
  <div class="page container">
    <div class="homeIntro">
      <video autoplay muted loop playsinline>
        <source src="@/assets/Videos/donut1.mp4" type="video/mp4">
      </video>
    </div>

    <div class="homeText">
      <h6>ONEONONE TEAM — TALENT COMMUNITY</h6>
      <h3>
        Independent design community represented by the group of strategists, designers, 3d-artists and developers who
        implement a package of a services on creation complex system of brand identification and brand promotion.
      </h3>
      <router-link to="/jobs" class="homeText-button">
        Join Us
      </router-link>
    </div>

    <div class="homeWorks">
      <template v-for="article in articles" >
        <router-link v-if="article.onMainPage && article.isActive"   :to="{ name: 'Work', params: { id: article._id }}" :id="article._id">

          <home-work
              :name="article.title"
              :image="article.main_img"
              :type="article.main_img_t"
          >
          </home-work>
        </router-link>
      </template>

    </div>

    <div class="homeText">
      <h6>WHAT WE DO</h6>
      <h3>
        Our community unites over 50+ exceptional specialists from around the world, enabling us to assemble highly
        effective and diverse teams to execute projects in the&nbsp;realms of creative development, branding and
        identity design, digital solutions and&nbsp;content.
      </h3>
      <router-link to="/works" class="homeText-button">
        Works
      </router-link>
    </div>

    <div class="homeSlider">
      <logo-scroller></logo-scroller>
    </div>

    <contact-form></contact-form>
  </div>

</template>

<style scoped lang="scss">
.homeIntro {
  width: 700px;
  height: 700px;
  margin: 0 auto 20px;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.homeText {
  text-align: center;
  margin-bottom: 60px;

  h3 {
    margin-top: 18px;
    margin-bottom: 30px;
  }

  &-button {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    border: 1px solid #171717;
    background: #fff;
    border-radius: 40px;
    transition: .2s ease;
    padding: 12px 24px;

    &:hover {
      color: #fff;
      background: #171717;
    }
  }
}

.homeWorks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-bottom: 60px;
}

@media screen and (max-width: 1399px) {

}

@media screen and (max-width: 999px) {
  .homeIntro {
    width: 600px;
    height: 600px;

    video {
      width: 100%;
    }
  }

  .homeText {
    h3 {
      padding: 0 48px;
    }
  }

  .homeWorks {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 809px) {
  .homeIntro {
    width: 320px;
    height: 320px;
    margin-bottom: 20px;
  }

  .homeWorks {
    gap: 24px;
  }

  .homeText {
    h3 {
      padding: 0;
      font-size: 24px;
    }
  }
}
</style>