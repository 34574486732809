<script>

</script>

<template>
  <div class="footer">
    <div class="footer-left">
      <div class="footer-left-top">
        <svg viewBox="0 0 1000.95 1000.95">
          <path
              d="M500.47 1000.95C224.51 1000.95 0 776.44 0 500.48S224.51 0 500.47 0s500.47 224.51 500.47 500.48-224.51 500.47-500.47 500.47Zm0-887.62c-213.48 0-387.15 173.67-387.15 387.15s173.67 387.15 387.15 387.15 387.15-173.67 387.15-387.15-173.67-387.16-387.15-387.16Z"
          ></path>
        </svg>
        <p>
          LINKING TALENT TO BRANDS
          FOR CREATIVE EXCELLENCE
        </p>
      </div>

      <div class="footer-icons mobile">
        <a href="https://www.instagram.com/oneonone.agency/">
          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17.99">
            <g id="Layer_1-2" data-name="Layer 1" fill="#1e1e24">
              <path class="cls-1" d="M14.44,8.99c0,3.01-2.44,5.44-5.44,5.44s-5.44-2.43-5.44-5.44c0-.36,.03-.71,.1-1.04H0v7.23c0,1.55,1.26,2.82,2.82,2.82H15.19c1.55,0,2.82-1.26,2.82-2.82V7.95h-3.67c.07,.34,.1,.68,.1,1.04ZM15.19,0H2.82C1.26,0,0,1.25,0,2.81v3.04H4.55c1-1.39,2.61-2.3,4.45-2.3s3.46,.91,4.45,2.3h4.56V2.81c0-1.56-1.27-2.81-2.82-2.81Zm1.38,3.46c0,.29-.23,.51-.5,.51h-1.51c-.27,0-.51-.22-.51-.51V1.96c0-.28,.23-.5,.51-.5h1.51c.27,0,.5,.22,.5,.5v1.51Zm-4.22,5.53c0-1.85-1.49-3.35-3.34-3.35s-3.35,1.5-3.35,3.35,1.5,3.35,3.35,3.35,3.34-1.49,3.34-3.35Z"/>
            </g>
          </svg>
        </a>
        <a href="https://www.linkedin.com/company/oneonone-team?trk=experience-timeline">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><path d="M 18.5 0 L 2.5 0 C 1.12 0 0 1.12 0 2.5 L 0 18.5 C 0 19.88 1.12 21 2.5 21 L 18.5 21 C 19.88 21 21 19.88 21 18.5 L 21 2.5 C 21 1.12 19.88 0 18.5 0 Z M 6.5 8 L 6.5 17.5 L 3.5 17.5 L 3.5 8 Z M 3.5 5.235 C 3.5 4.535 4.1 4 5 4 C 5.9 4 6.465 4.535 6.5 5.235 C 6.5 5.935 5.94 6.5 5 6.5 C 4.1 6.5 3.5 5.935 3.5 5.235 Z M 17.5 17.5 L 14.5 17.5 L 14.5 12.5 C 14.5 11.5 14 10.5 12.75 10.48 L 12.71 10.48 C 11.5 10.48 11 11.51 11 12.5 L 11 17.5 L 8 17.5 L 8 8 L 11 8 L 11 9.28 C 11 9.28 11.965 8 13.905 8 C 15.89 8 17.5 9.365 17.5 12.13 Z" fill="rgb(30, 30, 36)"></path></svg>
        </a>
        <a href="https://www.behance.net/oneonone">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 23 15"><path d="M 8.482 6.207 C 8.482 6.207 10.465 6.043 10.465 3.617 C 10.465 1.19 8.832 0 6.778 0 L 0 0 L 0 13.58 L 6.778 13.58 C 6.778 13.58 10.908 13.72 10.908 9.578 C 10.908 9.578 11.072 6.207 8.47 6.207 Z M 2.987 2.415 L 6.79 2.415 C 6.79 2.415 7.712 2.415 7.712 3.838 C 7.712 5.262 7.175 5.448 6.557 5.448 L 2.987 5.448 Z M 6.58 11.165 L 2.987 11.165 L 2.987 7.537 L 6.79 7.537 C 6.79 7.537 8.155 7.513 8.155 9.403 C 8.155 10.99 7.128 11.153 6.58 11.165 Z M 16.427 3.465 C 11.433 3.465 11.433 8.668 11.433 8.668 C 11.433 8.668 11.095 13.86 16.427 13.86 C 16.427 13.86 20.895 14.128 20.895 10.255 L 18.597 10.255 C 18.597 10.255 18.678 11.713 16.508 11.713 C 16.508 11.713 14.21 11.865 14.21 9.345 L 20.953 9.345 C 20.953 9.345 21.7 3.453 16.427 3.453 Z M 18.468 7.537 L 14.187 7.537 C 14.187 7.537 14.467 5.448 16.485 5.448 C 18.503 5.448 18.468 7.537 18.468 7.537 Z M 19.005 0.805 L 13.627 0.805 L 13.627 2.485 L 19.005 2.485 Z" fill="rgb(30,30,36)"></path></svg>
        </a>
      </div>
    </div>

    <div class="footer-right">
      <div class="footer-icons">
        <a href="https://www.behance.net/oneonone">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 15"><path d="M 8.482 6.207 C 8.482 6.207 10.465 6.043 10.465 3.617 C 10.465 1.19 8.832 0 6.778 0 L 0 0 L 0 13.58 L 6.778 13.58 C 6.778 13.58 10.908 13.72 10.908 9.578 C 10.908 9.578 11.072 6.207 8.47 6.207 Z M 2.987 2.415 L 6.79 2.415 C 6.79 2.415 7.712 2.415 7.712 3.838 C 7.712 5.262 7.175 5.448 6.557 5.448 L 2.987 5.448 Z M 6.58 11.165 L 2.987 11.165 L 2.987 7.537 L 6.79 7.537 C 6.79 7.537 8.155 7.513 8.155 9.403 C 8.155 10.99 7.128 11.153 6.58 11.165 Z M 16.427 3.465 C 11.433 3.465 11.433 8.668 11.433 8.668 C 11.433 8.668 11.095 13.86 16.427 13.86 C 16.427 13.86 20.895 14.128 20.895 10.255 L 18.597 10.255 C 18.597 10.255 18.678 11.713 16.508 11.713 C 16.508 11.713 14.21 11.865 14.21 9.345 L 20.953 9.345 C 20.953 9.345 21.7 3.453 16.427 3.453 Z M 18.468 7.537 L 14.187 7.537 C 14.187 7.537 14.467 5.448 16.485 5.448 C 18.503 5.448 18.468 7.537 18.468 7.537 Z M 19.005 0.805 L 13.627 0.805 L 13.627 2.485 L 19.005 2.485 Z" fill="rgb(30,30,36)"></path></svg>
        </a>
        <a href="https://www.instagram.com/oneonone.agency/">
          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17.99">
            <g id="Layer_1-2" data-name="Layer 1" fill="#1e1e24">
              <path class="cls-1" d="M14.44,8.99c0,3.01-2.44,5.44-5.44,5.44s-5.44-2.43-5.44-5.44c0-.36,.03-.71,.1-1.04H0v7.23c0,1.55,1.26,2.82,2.82,2.82H15.19c1.55,0,2.82-1.26,2.82-2.82V7.95h-3.67c.07,.34,.1,.68,.1,1.04ZM15.19,0H2.82C1.26,0,0,1.25,0,2.81v3.04H4.55c1-1.39,2.61-2.3,4.45-2.3s3.46,.91,4.45,2.3h4.56V2.81c0-1.56-1.27-2.81-2.82-2.81Zm1.38,3.46c0,.29-.23,.51-.5,.51h-1.51c-.27,0-.51-.22-.51-.51V1.96c0-.28,.23-.5,.51-.5h1.51c.27,0,.5,.22,.5,.5v1.51Zm-4.22,5.53c0-1.85-1.49-3.35-3.34-3.35s-3.35,1.5-3.35,3.35,1.5,3.35,3.35,3.35,3.34-1.49,3.34-3.35Z"/>
            </g>
          </svg>
        </a>
        <a href="https://www.linkedin.com/company/oneonone-team?trk=experience-timeline">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M 18.5 0 L 2.5 0 C 1.12 0 0 1.12 0 2.5 L 0 18.5 C 0 19.88 1.12 21 2.5 21 L 18.5 21 C 19.88 21 21 19.88 21 18.5 L 21 2.5 C 21 1.12 19.88 0 18.5 0 Z M 6.5 8 L 6.5 17.5 L 3.5 17.5 L 3.5 8 Z M 3.5 5.235 C 3.5 4.535 4.1 4 5 4 C 5.9 4 6.465 4.535 6.5 5.235 C 6.5 5.935 5.94 6.5 5 6.5 C 4.1 6.5 3.5 5.935 3.5 5.235 Z M 17.5 17.5 L 14.5 17.5 L 14.5 12.5 C 14.5 11.5 14 10.5 12.75 10.48 L 12.71 10.48 C 11.5 10.48 11 11.51 11 12.5 L 11 17.5 L 8 17.5 L 8 8 L 11 8 L 11 9.28 C 11 9.28 11.965 8 13.905 8 C 15.89 8 17.5 9.365 17.5 12.13 Z" fill="rgb(30, 30, 36)"></path></svg>
        </a>
      </div>
      <div class="footer-mails">
        <div class="footer-mail">
          <div class="footer-mail-head">work with us</div>
          <div class="footer-mail-text">
            <a href="mailto:hi@oneonone.team">hi@oneonone.team</a>
          </div>
        </div>
        <div class="footer-mail">
          <div class="footer-mail-head">join our community</div>
          <div class="footer-mail-text">
            <a href="mailto:go@oneonone.team">go@oneonone.team</a>
          </div>
        </div>
      </div>
      <div class="footer-copy">ONEONONE TEAM © 2023 ALL RIGHTS RESERVED</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer {
  width: 960px;
  margin: 50px auto 0;
  padding: 20px 0 32px;
  border-top: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: relative;
  //&::before {
  //  content: '';
  //  position: absolute;
  //  width: 100%;
  //  height: 1px;
  //  background: red;
  //  left: 0;
  //  top: 34px;
  //  z-index: -1;
  //}
  //
  //&::after {
  //  content: '';
  //  position: absolute;
  //  width: 100%;
  //  height: 1px;
  //  background: red;
  //  left: 0;
  //  top: 53px;
  //  z-index: -1;
  //}

  &-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-top {
      display: flex;
      gap: 10px;
    }

    svg {
      width: 35px;
      height: 35px;
    }

    p {
      font-size: 13px;
      line-height: 1.47;
      max-width: 180px;
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    gap: 48px;
  }

  &-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      line-height: 0.85;
      display: block;
      width: 20px;
      height: 20px;
    }
    svg {
      width: 20px;
      height: 20px;
    }

    &.mobile {
      display: none;
    }
  }

  &-mails {
    display: flex;
    flex-direction: column;
    height: 90px;
    justify-content: space-between;
    font-size: 13px;
    line-height: 1;
  }

  &-mail {
    cursor: pointer;

    &:first-child {
      padding-top: 3px;
    }

    &-head {
      color: #7E8086;
      text-transform: uppercase;
    }

    &-text {
      font-size: 15px;
      margin-top: 4px;
    }
  }

  &-copy {
    max-width: 156px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.46;
  }
}


@media screen and (max-width: 999px) {
  .footer-copy {
    display: none;
  }

  .footer {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
}

@media screen and (max-width: 809px) {
  .footer {
    width: calc(100% - 24px);

    &-left-top {
      gap: 10px;
    }

    &-icons {
      display: none;
      &.mobile {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
  }
}
</style>