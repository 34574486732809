<template>
  <div class="post">

    <div class="item__wrapper">
      <div class="item__inner" v-if="post">

        <div class="post__field">
          <h6>Заголовок test:</h6>
          <input class="post__input" type="text" v-model="post.title"/>
        </div>
        <div class="post__field">
          <h6>Подзаголовок: </h6>
          <input class="post__input" type="text" v-model="post.subtitle"/>
        </div>
        <div class="post__field">
          <h6>Обложка:</h6>
          <button @click="post.main_img_t = 'img'" :class="[post.main_img_t == 'img' ? 'active' : '']">картинка</button>
          <button @click="post.main_img_t = 'vid'" :class="[post.main_img_t == 'vid'? 'active' : '']">Видео</button>
          <input class="post__file" type="file" @change="handleMainIMGUpload($event)"/>
          <img class="post__img" :src="post.main_img" alt="" srcset="" v-if="post.main_img_t == 'img'">
          <video class="post__vid" :src="post.main_img" alt="" srcset="" v-if="post.main_img_t == 'vid'" autoplay loop muted playsinline></video>
        </div>


        <div class="post__blocks" v-for="(block, blockIndex) in post.blocks">
          <div class="block-btnsTop">
            <div>
              <button @click="moveBlockUp(blockIndex, block._id)">Поднять</button>
              <button @click="moveBlockDown(blockIndex, block._id)">Опустить</button>
            </div>
            <button class="btn-delete" @click="deleteMainBlock(blockIndex)">Удалить блок</button>
          </div>

          <h2>Блок контента:</h2>

          <div class="post__item" v-for="(content_block, index) in block.content_blocks">
            <h3 class="post__type">Тип контента: {{ content_block.type }}</h3>

            <div class="post__block"
                 v-if="content_block.type === 'image' || content_block.type === 'video' || content_block.type === 'slider'">
              <p>Загрузить изображение: </p>
              <select v-model="content_block.size" name="" id="">
                <option value="s">S (760x482)</option>
                <option value="m">M (900x506)</option>
                <option value="l">L (762x950)</option>
              </select>
              <input class="post__file" type="file" @change="handleFileUpload($event, selectedType,blockIndex, index)"/>
              <img v-for="imageUrl in content_block.data " class="post__img" :src="imageUrl" alt="" srcset="">
            </div>


            <div class="post__block" v-if="content_block.type === 'text'">
              <textarea class="post__input" type="text" v-model="content_block.data[0]"></textarea>
            </div>
            <button class="post__delete" @click="deleteBlock(index,blockIndex)">Удалить наполнение</button>
          </div>

          <div class="post__addblock">
            <select v-model="selectedType" name="" id="">
              <option value="text">Текст</option>
              <option value="image">Изображение</option>
              <option value="video">Видео</option>
              <option value="slider">Слайдер</option>
            </select>

            <button @click="(addContentBlock(blockIndex))">Добавить наполнение</button>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Loading...</p>
      </div>

      <button class="add_button" @click="addBlock()">Добавить блок</button>

      <button class="button__confirm" @click="editPost()">
        <span v-if="isLoading">
          Loading...
        </span>
        <span v-else="isLoading"> Сохранить</span>
      </button>
    </div>

  </div>
</template>

<script>
import AppService from "@/store/services";

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      post: null,
      selectedType: 'text',
      isLoading: false,
      selectedSize: 's',
      main_type: 'img'
    };
  },

  async created() {
    await this.getPost();
  },
  methods: {
    async getPost() {
      try {
        this.post = await AppService.getPostById(this.id);
      } catch (error) {
        console.error(error);
      }
    },

    addBlock() {
      if (!this.selectedType) {
        console.error('Error: No type selected for the new block.');
        return;
      }


      const newBlock = {
        content_blocks: [],

      };
      this.post.blocks.push(newBlock);
    },
    addContentBlock(blockIndex) {
      if (!this.selectedType) {
        console.error('Error: No type selected for the new block.');
        return;
      }

      const newContentBlock = {
        type: this.selectedType,
        data: [],
        size: 's'
      };

      this.post.blocks[blockIndex].content_blocks.push(newContentBlock);
    },
    async editPost() {
      this.isLoading = true;
      this.post.blocks = this.post.blocks.map(block => ({
        ...block,
        type: block.type
      }));

      try {
        await AppService.editPost(this.id, this.post);
        console.log(this.post);
        this.$router.push('/admin');
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    deleteBlock(index, parentIndex) {
      this.post.blocks[parentIndex].content_blocks.splice(index, 1);
    },
    deleteMainBlock(index) {
      this.post.blocks.splice(index, 1);
    },
    handleFileUpload(event, type, blockIndex = null, contentBlockIndex = null) {
      if (event.target.files) {
        const selected_file = event.target.files[0];
        if (selected_file) {
          const formData = new FormData();
          formData.append("file", selected_file);

          const BaseUrl = "/upload";
          fetch(BaseUrl, {
            method: "POST",
            body: formData
          }).then(async res => {
            if (res.ok) {
              const url = await res.json();
              console.log("Файл успешно загружен:", url.link);
              this.post.blocks[blockIndex].content_blocks[contentBlockIndex].data.push(url.link);
            } else {
              console.error(
                  "Ошибка при загрузке файла:",
                  res.status,
                  res.statusText
              );
            }
          });
        }
      }
    },
    handleMainIMGUpload(event) {
      if (event.target.files) {
        const selected_file = event.target.files[0];
        if (selected_file) {
          const formData = new FormData();
          formData.append("file", selected_file);

          const BaseUrl = "/upload";
          fetch(BaseUrl, {
            method: "POST",
            body: formData
          }).then(async res => {
            if (res.ok) {
              const url = await res.json();
              console.log("Файл успешно загружен:", url.link);
              this.post.main_img = url.link;
            } else {
              console.error(
                  "Ошибка при загрузке файла:",
                  res.status,
                  res.statusText
              );
            }
          });
        }
      }
    },
    async moveBlockUp(blockIndex, id) {
      if (blockIndex > 0) {
        const temp = this.post.blocks[blockIndex];
        this.post.blocks.splice(blockIndex, 1);
        this.post.blocks.splice(blockIndex - 1, 0, temp);
      }
    },

    async moveBlockDown(blockIndex, id) {
      if (blockIndex < this.post.blocks.length - 1) {
        const temp = this.post.blocks[blockIndex];
        this.post.blocks.splice(blockIndex, 1);
        this.post.blocks.splice(blockIndex + 1, 0, temp);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.post {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  padding: 0 30px;
  font-size: 16px;

  &__field {
    width: 100%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    img {
      max-width: 100%;
      margin-top: 20px;
      max-height: 500px;
      width: auto;
    }
  }

  &__input {
    width: 100%;
    resize: vertical;
    padding: 6px 2px;
    border-bottom: 2px solid #ccc;
    background: rgba(#fff, .4);
    font-size: 16px;
    margin-top: 10px;
  }

  &__img {
    width: 300px;
    max-width: 100%;
  }

  &__type {
    font-size: 18px;
    color: #2A2D31;
    margin-bottom: 15px;
  }

  &__blocks {
    margin-top: 20px;
    background: #eeeeee;
    padding: 20px;

    &:nth-child(2n) {
      background: #cccccc;
    }

    h2 {
      margin: 20px 0;
    }
  }

  &__file {
    display: block;
    margin: 10px 0;
  }

  &__delete {
    padding: 20px;
    border: none;
    background: #DC1515;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
  }

  &__block {
  }

  &__item {
    border-bottom: 2px solid black;
    padding-bottom: 20px;
  }

  &__addblock {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    button {
      padding: 20px;
      border: none;
      background: #082424;
      color: #fff;
    }
  }
}

.item__wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: rgba(#fff, .4);
  border-radius: 10px;
  height: 100%;
}

.item__inner {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

h6 {
  font-size: 18px;
}

.button__confirm {
  align-self: flex-start;
  display: inline-block;
  padding: 20px 40px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  background: #082424;
  color: #fff;

  &:hover {
    opacity: 0.6;
  }
}

.add_button {
  padding: 20px;
  border: none;
  background: #082424;
  color: #fff;
}

.block-btnsTop {
  display: flex;
  justify-content: space-between;

  button {
    padding: 10px;
    font-weight: 600;
    background: #171717;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
  }

  button + button {
    margin-left: 10px;
  }

  .btn-delete {
    background: #DC1515;
    color: #fff;
  }
}
button {
  border-radius: 4px;
  cursor: pointer;
}
button.active {
  background: #171717;
  color: #fff;
}
</style>
