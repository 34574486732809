<script>
import Advantage from '@/components/About/Advantage.vue'
import ContactForm from "@/components/Forms/ContactForm.vue";
import LogoScroller from "@/components/LogoScroller.vue";

export default {
  components: {
    LogoScroller,
    ContactForm,
    Advantage,
  }
}
</script>

<template>
  <div class="page container">
    <div class="aboutText">
      <h6>about</h6>
      <h3>
        We believe that strategically formed teams and dynamic creative collaborations enable us to uncover unique and innovative solutions to challenges.
      </h3>
    </div>
    <div class="introImg-wrapper">
      <video preload="metadata" autoplay loop playsinline :muted="true">
        <source src="/img/about-vawes.mp4" type="video/mp4">
      </video>
    </div>

    <div class="approach">
      <div class="approach-left">
        <h6>approach</h6>
      </div>
      <div class="approach-right">
        Welcome to our design studio, a creative hub specializing in the art of branding. We are passionate about crafting unique and compelling brand identities that leave a lasting impact. Our team of talented designers, strategists, and storytellers work together to create cohesive and memorable brand experiences across various touchpoints.
      </div>
    </div>

    <div class="hints">
      <advantage
          :icon="'/img/advantage-icon-1.webp'"
          :title="'Strategy of communications'"
          :description="'Everything we design is communication'"
          :tags="['Creative Direction', 'Campaign Concepts', 'Brand Activation', 'Brand Strategy']"></advantage>
      <advantage
          :icon="'/img/advantage-icon-2.webp'"
          :title="'Brand Identity'"
          :description="'Communicating who you are, without words'"
          :tags="['Art Direction', 'Design Systems', 'Visual Identity', 'Guidelines']"></advantage>
      <advantage
          :icon="'/img/advantage-icon-3.webp'"
          :title="'Brand design'"
          :description="'Tell everyone more about your brand'"
          :tags="['Design Concepts', 'Service Design', 'Graphic Design', 'Social Media Assets']"></advantage>
      <advantage
          :icon="'/img/advantage-icon-4.webp'"
          :title="'Brand Experience'"
          :description="'An environment for new opportunities'"
          :tags="['Exhibitions Design', 'Retail', 'HoReCa', 'Installations']"></advantage>
      <advantage
          :icon="'/img/advantage-icon-5.webp'"
          :title="'CGI & Animation'"
          :description="'Convey the meaning with dynamic visual presentation'"
          :tags="['3D Design', '3D Modelling', 'Digital Design', 'Motion Design']"></advantage>
      <advantage
          :icon="'/img/advantage-icon-6.webp'"
          :title="'Digital design & Development'"
          :description="'Create an amazing digital environment'"
          :tags="['UI/UX Design', 'No-Code', 'Creative Coding', 'Generative AI']"></advantage>
    </div>

    <logo-scroller></logo-scroller>

    <contact-form></contact-form>
  </div>
</template>

<style scoped lang="scss">
.page  {
  padding-top: 200px;
}

.aboutText {
  text-align: center;
  margin-bottom: 60px;

  h6 {
    margin-bottom: 20px;
  }
}

.introImg {
  width: 100%;
  mask: linear-gradient(90deg, transparent, #fff 10%, #fff 80%, transparent);

  &-wrapper {
    width: 100%;
    overflow: hidden;

    video {
      width: 100%;
    }
  }
}

.approach {
  margin-top: 60px;
  display: flex;
  align-items: center;
  gap: 32px;

  &-left {
    height: 96px;
    width: 20px;
    position: relative;

    h6 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(270deg);
      text-transform: uppercase;
    }
  }

  &-right {
    font-size: 24px;
    line-height: 1.3;
  }
}

.hints {
  padding: 60px 0;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 1399px) {

}

@media screen and (max-width: 999px) {
  .introImg-wrapper {
    height: auto;
  }
}

@media screen and (max-width: 809px) {
  .page {
    padding-top: 170px;
  }

  .introImg {
    mask: none;
  }

  .approach {
    flex-direction: column;
    gap: 16px;

    &-left h6 {
      left: 0;
      top: 0;
      transform: none;
      position: static;
    }

    &-left {
      width: auto;
      height: auto;
    }

    &-right {
      padding: 0 20px;
    }
  }

  .hints {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .advantage-tags {
    flex-wrap: wrap;
    .tag {
      font-size: 8px;
      padding: 4px 8px;
      white-space: nowrap;
    }
  }
}
</style>